const BASIC_REST = "";
const UNREGISTER_REST = "/unregistered/api";
const POST = "post";
const GET = "get";

const REGISTER_NEW_TEST = {
  url: UNREGISTER_REST + "/registerNewTest",
  method: POST,
};

const GET_INFO_PROVIDER = {
  url: UNREGISTER_REST + "/getInfoProvider",
  method: GET,
};

const SEARCH_REGISTERED_TEST = {
  url: UNREGISTER_REST + "/searchRegisteredTest",
  method: GET,
};

const TEST_PRAMETERS_CORRECTESS = {
  url: UNREGISTER_REST + "/testPaymentParametersCorrectness",
  method: GET,
};

const GET_INFO_ORDER = {
  url: UNREGISTER_REST + "/getInfoOrder",
  method: GET,
};

const CREATE_PAYMENT_INTENT = {
  url: UNREGISTER_REST + "/createPaymentIntent",
  method: POST,
};

export {
  REGISTER_NEW_TEST,
  GET_INFO_PROVIDER,
  SEARCH_REGISTERED_TEST,
  TEST_PRAMETERS_CORRECTESS,
  GET_INFO_ORDER,
  CREATE_PAYMENT_INTENT,
};
