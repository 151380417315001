// useAxios.js
import { useState, useMemo } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../store/Actions/ManagementAction";

const TIMEOUT_MINUTES = 10;
//timeout is in milliseconds
const TIMEOUT_VALUE = TIMEOUT_MINUTES * 60 * 1000;

const axiosInstance = axios.create({
  //baseURL: "/unregistered/api",
  timeout: TIMEOUT_VALUE,
  maxContentLength: 100000000,
  maxBodyLength: 1000000000,
});

const axiosController = new AbortController();

function fetcher(url) {
  return axiosInstance.get(url).then((res) => res.data);
}

function setContentEncoding(value) {
  axiosInstance.defaults.headers.common["Content-Encoding"] = value;
}

const useAxiosConfig = () => {
  //const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [pendingRequests, setPendingRequests] = useState(0);

  const dispatch = useDispatch();

  async function handleSuccessRequest(config) {
    setPendingRequests(pendingRequests + 1);
    if (axiosController.signal) config.signal = axiosController.signal;

    setContentEncoding("gzip");
    setIsLoading(true);
    return config;
  }

  function handleErrorRequest(error) {
    return Promise.reject(error);
  }

  function handleSuccessResponse(response) {
    setIsLoading(false);
    return response;
  }

  function handleErrorResponse(error) {
    setIsLoading(false);
    setPendingRequests(pendingRequests - 1);
    if (axios.isAxiosError(error)) {
      if (error.response) {
        /*notify({
          severity: "error",
          summary: error.name,
          detail: error.message + "-" + error.response?.data.message,
        });*/
        //console.log("This is the error branch");
        //throw new Error(error.response.data.message);
        return error.response;
      }
    } else {
      /*notify({
        severity: "error",
        summary: t("notifyErrors.generic"),
      });*/
      //throw new Error("Unexpected error!");
      //console.log("this is the warning branch");
      //throw new Error(error.message);
    }
  }
  useMemo(
    () =>
      axiosInstance.interceptors.request.use(
        handleSuccessRequest,
        handleErrorRequest
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useMemo(
    () =>
      axiosInstance.interceptors.response.use(
        handleSuccessResponse,
        handleErrorResponse
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  return {
    axiosInstance,
    axiosController,
    fetcher,
    isLoading,
  };
};

export default useAxiosConfig;
