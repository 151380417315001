import React from "react";

const RegisterFill = React.lazy(() => import("./pages/Register_fill"));
const ErrorPage = React.lazy(() => import("./pages/ErrorPage"));
const PayForTest = React.lazy(() => import("./pages/PayForTest"));
const PaymentResult = React.lazy(() => import("./pages/PaymentResult"));

const routes = [
  //  { path: "/", exact: true, name: "PatientList" },
  { path: "/unregisteredTest", name: "Register", element: RegisterFill },
  { path: "/error", name: "Error", element: ErrorPage },
  { path: "/payForTest", name: "Payment", element: PayForTest },
  { path: "/paymentResult", name: "PaymentResult", element: PaymentResult },
];

export default routes;
