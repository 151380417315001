import { useState } from "react";
import {
  CREATE_PAYMENT_INTENT,
  GET_INFO_ORDER,
  GET_INFO_PROVIDER,
  REGISTER_NEW_TEST,
  SEARCH_REGISTERED_TEST,
  TEST_PRAMETERS_CORRECTESS,
} from "./constants";

import useAxiosConfig from "./useAxiosConfig";

const useApi = () => {
  const { axiosInstance, isLoading, renewTimeout } = useAxiosConfig();

  const register_new_test = async (datas, funct) => {
    const { url, method } = REGISTER_NEW_TEST;
    const result = await axiosInstance[method](url, datas, {
      onUploadProgress: funct,
    });

    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return result;
  };

  const get_info_provider = async (params, funct) => {
    const { url, method } = GET_INFO_PROVIDER;
    const result = await axiosInstance[method](url, {
      params: params,
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return {};
  };

  const get_info_order = async (params, funct) => {
    const { url, method } = GET_INFO_ORDER;
    const result = await axiosInstance[method](url, {
      params: params,
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return {};
  };

  const test_parameters_correctness = async (params, funct) => {
    const { url, method } = TEST_PRAMETERS_CORRECTESS;
    const result = await axiosInstance[method](url, {
      params: params,
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return {};
  };

  const search_registered_test = async (params, funct) => {
    //orderId
    const { url, method } = SEARCH_REGISTERED_TEST;
    const result = await axiosInstance[method](url, {
      params: params,
      onDownloadProgress: funct,
    });
    if (result && result.data) {
      const items = result.data;
      //console.log(items);
      //since we need to add a key, we add a key to the resulting array.
      const keyed_items = items.map((item, id) => {
        return { ...item, id: id, key: id };
      });
      return keyed_items;
    }

    return [];
  };
  const create_payment_intent = async (params, funct) => {
    //patientId
    const { url, method } = CREATE_PAYMENT_INTENT;
    const result = await axiosInstance[method](url, params);
    if (result && result.data) {
      const items = result.data;
      return items;
    }

    return {};
  };

  return {
    register_new_test,
    get_info_provider,
    get_info_order,
    search_registered_test,
    test_parameters_correctness,
    create_payment_intent,
    isLoading,
    renewTimeout,
  };
};

export default useApi;
