import React, { Suspense, useState } from "react";
import { Route, Routes, Navigate, useSearchParams } from "react-router-dom";

import { Sidebar } from "./components/index";
import routes from "../routes";
import useApi from "../api/useApi";
import { useMediaQuery } from "@mui/material";

function Layout() {
  //url parameters
  const [queryParameters] = useSearchParams();

  const emailAddress = queryParameters.get("emailAddress");
  const patientFullName = queryParameters.get("patientFullName");
  const organization = queryParameters.get("organization");
  const orderId = queryParameters.get("orderId");

  const [correctParameters, setCorrectParameters] = useState(false);
  const isNotMobile = useMediaQuery("(min-width:600px)");

  const { isLoading, test_parameters_correctness } = useApi();

  useState(() => {
    if (!correctParameters) {
      test_parameters_correctness({
        orderId: orderId,
        emailAddress: emailAddress,
        patientFullName: patientFullName,
        organization: organization,
      }).then((data) => {
        if (data) {
          setCorrectParameters(data.result);
        }
      });
    }
  }, []);

  const correctRouter = () => {
    return <Route path="/" element={<Navigate to="/payForTest" replace />} />;
  };

  return (
    <div className="main">
      {emailAddress &&
      patientFullName &&
      organization &&
      orderId &&
      correctParameters &&
      isNotMobile ? (
        <Sidebar />
      ) : (
        <div />
      )}
      <Suspense>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            );
          })}
          {correctRouter()}
        </Routes>
      </Suspense>
    </div>
  );
}

export default Layout;
