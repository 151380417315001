//import useState hook to create menu collapse state
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemIcon,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { Payment } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import useApi from "../../api/useApi";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    width: 300,
    background: theme.palette.primary.main,
    height: "100%",
    position: "fixed",
    top: 0,
    bottom: 0,
  },
  avatar: {
    margin: "0.5rem auto",
    padding: "1rem",
    width: theme.spacing(13),
    height: theme.spacing(13),
  },
  label: {
    color: "white",
    textAlign: "left",
  },
  logo: {
    display: "block",
    maxWidth: "80%",
    maxHeight: "100%",
    margin: "auto",
    marginTop: "30px",
  },
  link: {
    textDecoration: "none",
    color: "white",
    textColor: "white",
  },
}));

export default function Sidebar() {
  const [patientName, setPatientName] = useState("");
  const [providerName, setProviderName] = useState("");
  const [queryParameters] = useSearchParams();
  const isNotMobile = useMediaQuery("(min-width:600px)");
  const { get_info_order } = useApi();

  const classes = useStyles();

  useEffect(() => {
    const patientFullName = queryParameters.get("patientFullName");
    const orderId = queryParameters.get("orderId");
    setPatientName(patientFullName);
    setProviderName(queryParameters.get("organization"));
  }, [queryParameters]);

  const sidebarMenus = () => {
    let listItems = [];

    listItems.push({
      listIcon: <Payment style={{ color: "white" }} />,
      listText: "PRS Test Payment",
      path: "/payForTest",
    });

    return listItems;
  };

  return (
    <>
      {isNotMobile ? (
        <Box className={classes.sidebar} component="div">
          <img
            alt="allelica"
            className={classes.logo}
            src="../logo-white.png"
          />
          <Grid
            container
            directon={"row"}
            justifyContent={"left"}
            alignItems={"center"}
            ml={2}
            mt={2}
            spacing={2}
          >
            <Grid item>
              <Avatar />
            </Grid>
            <Grid item>
              <label className={classes.label}>{patientName}</label>
              <br />

              <label className={classes.label}>({providerName})</label>
            </Grid>
          </Grid>
          <Grid
            container
            directon={"column"}
            justifyContent={"space-between"}
            style={{ bottom: "0" }}
          >
            <Grid item alignItems={"center"}>
              <List style={{ position: "absolute", width: "100%" }}>
                {/*<ListItem className={classes.link} button onClick={handleClick}>
                <ListItemIcon>
                  <Group style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Predict" className={classes.link} />
                {open ? (
                  <ExpandLess style={{ color: "white" }} />
                ) : (
                  <ExpandMore style={{ color: "white" }} />
                )}
              </ListItem>*/}
                {/*<Collapse in={open} timeout="auto" unmountOnExit>*/}
                <List>
                  {sidebarMenus().map((listItem, index) => (
                    <ListItem
                      className={classes.link}
                      button
                      key={index}
                      to={listItem.path}
                      component={Link}
                      style={{ color: "white" }}
                    >
                      <ListItemIcon>{listItem.listIcon}</ListItemIcon>
                      {listItem.listText}
                    </ListItem>
                  ))}
                </List>
                {/*</Collapse>*/}
              </List>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <div />
      )}
    </>
  );
}
